import { cva } from "class-variance-authority";

// Component model for the list item component
class FcListItemModel {
	/**
	 * Returns list of classes for a specific list item variant
	 */
	public variants: Function = cva(["w-full", "flex", "justify-between", "space-x-4"], {
		variants: {
			hasBackground: {
				true: ["p-4", "bg-white", "rounded-md", "border", "border-gray-300"]
			}
		},
	});
}

// Export the component model
export const component: FcListItemModel = new FcListItemModel();