import { StoreCarrierCageModel } from "@/data/models/store-carrier-cage-model";
import { StoreModel } from "@/data/models/store-model";
import { FcStatusScreen } from "@/fc-components/fc-status-screen/fc-status-screen-types";

/**
 * View state for the carrier collection view.
 */
export class CarrierCollectionState {
	/**
	 * Stores the barcode for the package.
	 */
	public barcode: string = "";

	/**
	 * Stores the stores.
	 */
	public stores: Array<StoreModel> = [];

	/**
	 * Stores the carrier cages.
	 */
	public carrierCages: Array<StoreCarrierCageModel> = [];

	/**
	 * Stores a list of collected cages.
	 */
	public collectedCages: Array<number> = [];

	/**
	 * Stores the selected carrier.
	 */
	public selectedCarrier: StoreCarrierCageModel | undefined;

	/**
	 * True if the modal is busy loading.
	 */
	public isLoading: boolean = false;

	/**
	 * True if the modal is busy doing something.
	 */
	public isBusy: boolean = false;

	/**
	 * Stores content for the status screen.
	 */
	public statusScreenContent: FcStatusScreen | undefined;
}