import { EndpointResponse } from "@/data/endpoint/types";
import * as Endpoint from "@/data/endpoint/endpoint";
import { PackageModel } from "../models/package-model";
import { PackagesModel } from "../models/packages-model";

// ----------------------------------------------------
// Provides data access functions for packages.
// ----------------------------------------------------

/**
 * Get a list of packages.
 */
export async function getPackagesAsync(barcode: string, packed: boolean, store_id?: number): Promise<EndpointResponse<PackagesModel>> {
	return Endpoint.getAsync(`packages?barcode=${encodeURIComponent(barcode)}&limit=50&only_packed=${packed}${store_id ? `&store_id=${store_id}` : ""}`);
}

/**
 * Get a list of packages.
 */
export async function updatePackageAsync(id: number, body: BodyInit): Promise<EndpointResponse<PackageModel>> {
	return Endpoint.patchAsync(`packages/${id}`, body);
}