/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable max-depth */
import { ViewModelBase } from "../base-classes/view-model-base";
import { CarrierCollectionState } from "./carrier-collection-state";
import { reactive } from "vue";
import { EndpointResponse } from "@/data/endpoint/types";
import { StoreCageModel } from "@/data/models/store-cage-model";
import { DatawedgeUtilities } from "@/utilities/datawedge-utilities";
import { ScanListenerEvent } from "capacitor-datawedge";
import * as StoresProvider from "@/data/providers/stores-provider";
import { FcListItemContentItem } from "@/fc-components/fc-list-item/fc-list-item-types";
import { StoreCarrierCageModel } from "@/data/models/store-carrier-cage-model";
import { CarrierModel } from "@/data/models/carrier-model";
import { NavigationUtilities } from "@/utilities/navigation-utilities";

class CarrierCollectionModel extends ViewModelBase {
	/**
	 * Specifies the localization namespace to use for getting localized text values.
	 */
	protected getLocalizationNamespace(): string {
		return "carrier-collection";
	}

	/**
	 * Returns the text to display in the page title of the view.
	 */
	public getPageTitle(): string {
		return this.getText("carrier-collection");
	}
    
	/**
	 * Reactive instance of the view state.
	 */
	public state: CarrierCollectionState = reactive(new CarrierCollectionState());

	/**
	 * Initalize the view.
	 */
	public async init(): Promise<void> {
		this.state.isLoading = true;
		DatawedgeUtilities.disableScanning();
		await this.getCarrierCagesFromAPI();
		this.state.isLoading = false;
		this.registerScanListener();
	}
	
	/**
	 * Sets the content of the status screen.
	 */
	private setStatusScreenContent(label: string, icon: Array<string>, color: string): void {
		this.state.statusScreenContent = {
			label: this.getText(label),
			icon,
			color
		};

		setTimeout(() => {
			this.state.statusScreenContent = undefined;
		}, 1000);
	}

	/**
	 * Fetches a list of carrier cages for each store.
	 */
	private async getCarrierCagesFromAPI(): Promise<void> {
		const response: EndpointResponse<Array<StoreCarrierCageModel>> = await StoresProvider.getStoreCarrierCagesAsync();

		if (response.wasSuccessful && response.data) {
			this.state.carrierCages = response.data;
		}
	}

	/**
	 * Returns a cage by its code.
	 */
	private getCageFromCode(code: string): StoreCageModel | undefined {
		if (this.state.selectedCarrier) {
			return this.state.selectedCarrier.cages.find((cage: StoreCageModel) => {
				return cage.code === code;
			});
		}
		else {
			return undefined;
		}
	}

	/**
	 * True if all cages have been collected.
	 */
	private hasEveryCageBeenCollected(): boolean | undefined {
		return this.state.selectedCarrier?.cages.every((cage: StoreCageModel) => {
			return this.state.collectedCages.includes(cage.id);
		});
	}

	/**
	 * Determines the next action after the user has scanned a cage.
	 */
	private determineNextActionAfterCageCollection(): void {
		const hasEveryCageBeenCollected: boolean | undefined = this.hasEveryCageBeenCollected();

		if (hasEveryCageBeenCollected) {
			this.setStatusScreenContent(
				"collection-successful",
				["far", "circle-check"],
				"green"
			);

			setTimeout(() => {
				this.state.selectedCarrier = undefined;
				this.init();
			}, 1000);
		}
		else {
			this.setStatusScreenContent(
				"cage-collected",
				["far", "circle-check"],
				"green"
			);
		}
	}

	/**
	 * Sets a cage to be collected.
	 */
	private async setCageToCollected(barcode: string): Promise<void> {
		const cage: StoreCageModel | undefined = this.getCageFromCode(barcode);

		if (cage) {
			this.state.isBusy = true;
			
			const response: EndpointResponse<StoreCageModel> = await StoresProvider.setStoreCageToCollectedAsync(cage.id);

			if (response.wasSuccessful && response.data) {
				this.state.collectedCages.push(cage.id);
				this.state.isBusy = false;
				this.determineNextActionAfterCageCollection();
			}
		}
		else {
			this.setStatusScreenContent(
				"invalid-cage",
				["far", "ban"],
				"red"
			);
		}
	}
	
	/**
	 * Registers a listener to listen for scan events.
	 */
	private registerScanListener(): void {
		DatawedgeUtilities.registerScanListener((response: ScanListenerEvent) => {
			this.onScan(response.data);
		});
	}

	/**
	 * Called when the scan listener raises an event, decides what action needs to be run.
	 */
	public onScan(barcode: string): void {
		this.formatBarcode(barcode);
		this.state.barcode = "";
		if (barcode.length > 0) {
			this.setCageToCollected(barcode);
		}
	}

	/**
	 * True if there is no selected carrier.
	 */
	public isCarrierSelectionScreenVisible(): boolean {
		return this.state.selectedCarrier === undefined && this.getFilteredCarriers().length > 0;
	}

	/**
	 * Returns the selected carrier in a list item format.
	 */
	public getSelectedCarrierForListItem(): object {
		return {
			thumbnailImage: `/images/carriers/${this.state.selectedCarrier?.carrier.code}.svg`,
			leftTitle: this.state.selectedCarrier?.carrier.description
		};
	}

	/**
	 * Returns a title for the carrier selection screen.
	 */
	public getCarrierSelectionScreenTitle(): string {
		return this.getText("carriers", [this.getFilteredCarriers().length]);
	}

	/**
	 * Returns a list of carriers filtered by scanned cages.
	 */
	public getFilteredCarriers(): Array<StoreCarrierCageModel> {
		return this.state.carrierCages.filter((carrier: StoreCarrierCageModel) => {
			return !carrier.cages.every((cage: StoreCageModel) => {
				return this.state.collectedCages.includes(cage.id);
			});
		});
	}

	/**
	 * Returns an image for a carrier.
	 */
	public getCarrierThumbnailImage(carrier: CarrierModel): string {
		return `/images/carriers/${carrier.code}.svg`;
	}

	/**
	 * Returns the amount of cages left to scan for a carrier.
	 */
	public getCagesToScanCount(storeCarrier: StoreCarrierCageModel): number {
		return storeCarrier.cages.filter((cage: StoreCageModel) => {
			return !this.state.collectedCages.includes(cage.id);
		}).length;
	}

	/**
	 * Returns a cage count title for a carrier.
	 */
	public getCarrierCageCountTitle(storeCarrier: StoreCarrierCageModel): string {
		return this.getText("cages-to-scan", [this.getCagesToScanCount(storeCarrier)]);
	}

	/**
	 * Called when a carrier is clicked.
	 * Sets the selected carrier.
	 */
	public onCarrierClicked(storeCarrier: StoreCarrierCageModel): void {
		DatawedgeUtilities.enableScanning();
		this.state.selectedCarrier = storeCarrier;
	}

	/**
	 * Returns a title for the collection screen.
	 */
	public getCollectionScreenTitle(): string {
		return this.getText("cages-title", [this.state.selectedCarrier?.cages.length.toString() as string]);
	}

	/**
	 * Returns content for a cage list item.
	 */
	public getCageListItemContent(cage: StoreCageModel): Array<FcListItemContentItem> {
		return [
			{
				leftText: this.getText("packages", [cage.number_of_packages])
			}
		];
	}

	/**
	 * True if there is a selected carrier.
	 */
	public isScanInputVisible(): boolean {
		return this.state.selectedCarrier !== undefined;
	}

	/**
	 * True if there are no carrier cages, if so, show the empty state.
	 */
	public isNoCollectionsFoundEmptyStateVisible(): boolean {
		return this.getFilteredCarriers().length === 0 && !this.state.isLoading;
	}

	/**
	 * True if the carrier collection view is busy.
	 */
	public isLoaderVisible(): boolean {
		return this.state.isLoading || this.state.isBusy;
	}

	/**
	 * Returns text for the loader component.
	 */
	public getLoaderText(): string {
		if (this.state.isLoading) {
			return this.getText("loading");
		}
		else if (this.state.isBusy) {
			return this.getText("processing");
		}
		else {
			return "";
		}
	}

	/**
	 * Returns an icon to display in the header.
	 */
	public getHeaderIcon(): Array<string> | undefined {
		if (this.state.selectedCarrier) {
			return ["fas", "chevron-left"];
		}
		else {
			return ["fas", "bars"];
		}
	}

	/**
	 * Called when the user clicks the header icon.
	 */
	public onHeaderIconClicked(): void {
		if (this.state.selectedCarrier) {
			this.init();
			this.state.selectedCarrier = undefined;
		}
		else {
			NavigationUtilities.isNavigationOpen.value = true;
		}
	}
}

// Export the view model.
export const view: CarrierCollectionModel = new CarrierCollectionModel();