import type { CustomError } from "@/utilities/custom-error";
import { logError, logWarning } from "@/utilities/log";
import * as msal from "@azure/msal-browser";
import type { IdTokenClaims } from "@azure/msal-common";
import { appConfig } from "./config-provider";

// Model for an authenticated account
export interface AuthenticatedAccount {
	isValid: boolean;
	emailAddress: string;
	idToken: string;
	expiry: number;
	idTokenClaims: IdTokenClaims;
}

// A reference to the authenticated account.
export const authenticatedAccount: AuthenticatedAccount = {
	isValid: false,
	emailAddress: "",
	idToken: "",
	expiry: 0,
	idTokenClaims: {}
};

const TAG: string = "AuthenticationProvider";

export function configureMSAL(): msal.PublicClientApplication {
	// Define MSAL Configuration
	const config: msal.Configuration = {
		auth: {
			clientId: appConfig.configuration["WMSClient:ADB2C:ClientID"],
			authority: `${appConfig.configuration["WMSClient:ADB2C:Authority"]}/${appConfig.configuration["WMSClient:ADB2C:Policy:Signin"]}`,
			redirectUri: location.hostname === "localhost" ? `http://${location.host}` : `https://${location.host}`,
			navigateToLoginRequestUrl: true,
			postLogoutRedirectUri: location.hostname === "localhost" ? `http://${location.host}` : `https://${location.host}`,
			knownAuthorities: [
				`${appConfig.configuration["WMSClient:ADB2C:Authority"]}/${appConfig.configuration["WMSClient:ADB2C:Policy:Signin"]}`
			]
		},
		cache: {
			cacheLocation: "localStorage",
		},
		system:{
			iframeHashTimeout: 10000,
			tokenRenewalOffsetSeconds: 100
		}
	};

	// Define the MSAL Instance
	const msalInstance: msal.PublicClientApplication = new msal.PublicClientApplication(config);

	return msalInstance;
}

// Define scopes for login
const loginRequest: msal.RedirectRequest = {
	scopes: ["openid", "profile"]
};

// Define scopes for login with account
const accountRequest: msal.RedirectRequest = {
	account: configureMSAL().getAllAccounts()[0],
	scopes: ["openid", "profile"]
};

/**
 * Get a new token for the current user
 */
export async function getToken(): Promise<void> {
	try {
		const response: msal.AuthenticationResult = await configureMSAL().acquireTokenSilent(accountRequest);
		authenticatedAccount.idToken = response.idToken;
		if (response.account) {
			authenticatedAccount.emailAddress = response.account.username;
			authenticatedAccount.idTokenClaims = response.idTokenClaims;
			authenticatedAccount.expiry = Number(authenticatedAccount.idTokenClaims.exp);
			authenticatedAccount.isValid = true;
		}
	}
	catch (error) {
		logError(TAG, error as CustomError);
	}
}

/**
 * Sign out the current user
 */
export async function signOut(): Promise<void> {
	localStorage.removeItem("authority");
	await configureMSAL().logoutRedirect(accountRequest);
}

/**
 * Setup background token refresh
 */
export async function acquireTokenSilent(): Promise<string> {
	configureMSAL().setActiveAccount(configureMSAL().getAllAccounts()[0]);

	const tokenRequest: msal.RedirectRequest = {
		account: configureMSAL().getAllAccounts()[0],
		scopes: ["openid", "profile"]
	};
	
	let idToken: string = authenticatedAccount.idToken;

	if (Object.keys(appConfig.configuration).length !== 0) {
		if (new Date(authenticatedAccount.expiry*1000) < new Date()) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			await configureMSAL().acquireTokenSilent(tokenRequest).then((tokenResponse: any) => {
				authenticatedAccount.idToken = tokenResponse.idToken;
				idToken = tokenResponse.idToken;
				authenticatedAccount.idTokenClaims = tokenResponse.idTokenClaims;
				authenticatedAccount.expiry = Number(authenticatedAccount.idTokenClaims.exp);
			}).catch(async (error: CustomError) => {
				logError("AUTH", error);
				await signOut();
			});
		}
	}

	return idToken;
}

export async function getIDToken(): Promise<string> {
	await acquireTokenSilent();
	return authenticatedAccount.idToken;
}

/**
 * Authenticate a user with ADB2C
 */
export async function authenticateUser(): Promise<void> {
	if (configureMSAL().getAllAccounts()[0]) {
		await getToken();
	}
	else {
		try {
			const authResult: msal.AuthenticationResult | null = await configureMSAL().handleRedirectPromise();
			if (authResult) {
				if (authResult.account) { 
					authenticatedAccount.isValid = true;
					authenticatedAccount.idTokenClaims = authResult.idTokenClaims;
					authenticatedAccount.expiry = Number(authenticatedAccount.idTokenClaims.exp);
					authenticatedAccount.emailAddress = authResult.account.username;
					authenticatedAccount.idToken = authResult.idToken;
				}
			}
			else {
				// eslint-disable-next-line no-console
				console.log("Need to reauthenticate");
				await configureMSAL()["browserStorage"].clear();
				await configureMSAL().loginRedirect(loginRequest);
			}
		}
		catch (error) {
			logError(TAG, error as CustomError);
		}
	}
}