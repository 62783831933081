import { createRouter, createWebHistory, Router, RouteRecordRaw } from "vue-router";
import { carrierCollectionRoutes } from "./views/carrier-collection/routes";
import { scanToCageRoutes } from "./views/scan-to-cage/routes";

const routes: Array<RouteRecordRaw> = [
	...scanToCageRoutes,
	...carrierCollectionRoutes
];

const router: Router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
