import type { RouteRecordRaw } from "vue-router";
import { View } from "@/views/constants";

import CarrierCollection from "./carrier-collection.vue";

export const carrierCollectionRoutes: Array<RouteRecordRaw> = [
	{
		path: "/carrier-collection",
		name: View.CarrierCollection,
		component: CarrierCollection,
		meta: {
			key: "carrier-collection"
		}
	}
];

