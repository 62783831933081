import { cva } from "class-variance-authority";

// Component model for the icon component
class FcIconModel {
	/**
	 * Returns list of classes for a specific icon variant
	 */
	public variants: Function = cva(["font-rubik"], {
		variants: {
			size: {
				"xs": "text-xs",
				"sm": "text-sm",
				"normal": "text-base",
				"md": "text-md",
				"lg": "text-lg",
				"xl": "text-xl",
				"2xl": "text-2xl",
				"3xl": "text-3xl",
				"4xl": "text-4xl",
				"5xl": "text-5xl",
				"6xl": "text-6xl",
				"7xl": "text-7xl",
				"8xl": "text-8xl",
				"9xl": "text-9xl"
			},
			color: {
				blackcurrant: "text-blackcurrant-500",
				green: "text-green-500",
				gray: "text-gray-500",
				white: "text-white"
			}
		},
		defaultVariants: {
			size: "normal",
			color: "blackcurrant"
		}
	});
}

// Export the component model
export const component: FcIconModel = new FcIconModel();