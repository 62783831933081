import { ref, Ref } from "vue";

/**
 * Utility functions for the navigation.
 */
export class NavigationUtilities {
	/**
	 * True if the navigation is open.
	 */
	public static isNavigationOpen: Ref<boolean> = ref(false);

	/**
	 * Returns navigation options.
	 */
	public static options: Array<any> = [
		{
			label: "Scan to Cage",
			link: "/"
		},
		{
			label: "Carrier Collection",
			link: "/carrier-collection"
		}
	];
}