import { library } from "@fortawesome/fontawesome-svg-core";
import { faBan, faBarcodeScan, faBox, faBoxesStacked, faCheckCircle, faFaceThinking, faQuestionCircle, faRotateLeft, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { faCheckCircle as fasCheckCircle, faBars, faChevronLeft } from "@fortawesome/pro-solid-svg-icons";

library.add(
	faTimes, 
	faQuestionCircle, 
	faBarcodeScan,
	faBars,
	faBox,
	faBan,
	faCheckCircle,
	faRotateLeft,
	faFaceThinking,
	faBoxesStacked,
	fasCheckCircle,
	faChevronLeft
);