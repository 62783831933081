/* eslint-disable max-depth */
import { ViewModelBase } from "../base-classes/view-model-base";
import { ScanToCageState } from "./scan-to-cage-state";
import { reactive } from "vue";
import { EndpointResponse } from "@/data/endpoint/types";
import { PackagesModel } from "@/data/models/packages-model";
import { CarrierServiceModel } from "@/data/models/carrier-service-model";
import { PackageModel } from "@/data/models/package-model";
import { StoreCageModel } from "@/data/models/store-cage-model";
import { DatawedgeUtilities } from "@/utilities/datawedge-utilities";
import { ScanListenerEvent } from "capacitor-datawedge";
import * as PackagesProvider from "@/data/providers/packages-provider";
import * as StoresProvider from "@/data/providers/stores-provider";
import { FcListItemContentItem } from "@/fc-components/fc-list-item/fc-list-item-types";
import { NavigationUtilities } from "@/utilities/navigation-utilities";

class ScanToCageModel extends ViewModelBase {
	/**
	 * Specifies the localization namespace to use for getting localized text values.
	 */
	protected getLocalizationNamespace(): string {
		return "scan-to-cage";
	}

	/**
	 * Returns the text to display in the page title of the view.
	 */
	public getPageTitle(): string {
		return this.getText("scan-to-cage");
	}
    
	/**
	 * Reactive instance of the view state.
	 */
	public state: ScanToCageState = reactive(new ScanToCageState());

	public init(): void {
		this.registerScanListener();
	}
	
	/**
	 * Clears the view and allows the user to start again.
	 */
	private clear(): void {
		this.state.barcode = "";
		this.state.packages = undefined;
		this.state.cage = undefined;
	}

	/**
	 * Sets the content of the status screen.
	 */
	private setStatusScreenContent(label: string, icon: Array<string>, color: string): void {
		this.state.statusScreenContent = {
			label: this.getText(label),
			icon,
			color
		};

		setTimeout(() => {
			this.state.statusScreenContent = undefined;
		}, 1000);
	}

	/**
	 * Registers a listener to listen for scan events.
	 */
	private registerScanListener(): void {
		DatawedgeUtilities.enableScanning();
		DatawedgeUtilities.registerScanListener((response: ScanListenerEvent) => {
			this.onScan(response.data);
		});
	}

	/**
	 * Called when the scan listener raises an event, decides what action needs to be run.
	 */
	public onScan(barcode: string): void {
		this.formatBarcode(barcode);
		barcode = barcode.replace(/\s/g,"");
		this.state.barcode = "";
		if (barcode.length > 0) {
			// Subsequent Scans
			if (this.state.packages) {
				this.subsequentScans(barcode);
			}
			// First Scan
			else {
				this.scanInitialPackage(barcode);
			}
		}
	}
    
	/**
	 * Called whenever the user scans the first package.
	 */
	private async scanInitialPackage(barcode: string): Promise<void> {
		this.state.isBusy = true;
		
		// Call the WMS BFF API GET /packages Endpoint passing the barcode and only_packed (set to 1) parameters
		const response: EndpointResponse<PackagesModel> = await PackagesProvider.getPackagesAsync(barcode, true);

		if (response.wasSuccessful && response.data) {
			// Where this data model contains information, this must be retained in memory so it can be checked at a later stage.
			if (response.data.paging_info.fetched > 0) {
				response.data.data = response.data.data.map((packageModel: PackageModel) => {
					return {
						...packageModel,
						barcode
					};
				});
				this.state.packages = response.data;
				this.determineCompatiableCarrierServices();
			}
			// Where the data model is empty, the user will be prompted with a warning "Invalid Package"
			else {
				this.setStatusScreenContent(
					"invalid-package",
					["far", "ban"],
					"red"
				);
			}
		}

		this.state.isBusy = false;
	}

	/**
	 * Determines what carrier services are compatible with the first package.
	 */
	private async determineCompatiableCarrierServices(): Promise<void> {
		this.state.isBusy = true;

		// Where 1 or more packages are returned, the system will call the WMS BFF API GET /store_cages/compatible_carrier_services Endpoint, passing the carrrier_service_id and the store_id of the first package returned.
		if (this.state.packages && this.state.packages.data.length > 0) {
			const carrierServiceId: number = this.state.packages.data[0].carrier_service.id;
			const storeId: number = this.state.packages.data[0].store_id;

			const response: EndpointResponse<Array<CarrierServiceModel>> = await StoresProvider.getCompatibleCarrierServicesAsync(carrierServiceId, storeId);

			// This endpoint will return a data model including the the Id's for all carrier services that are compatible with that of the first package found. 
			if (response.wasSuccessful && response.data) {
				// These carrier service id's should be retained in memory, until the user ultimately scans the cage, at which point they will be cleared until the next initial package is found.
				this.state.compatibleCarrierServices = response.data;
			}
		}

		this.state.isBusy = false;
	}

	/**
	 * Called whenever the user scans a barcode that isn't the first package.
	 */
	private async subsequentScans(barcode: string): Promise<void> {
		this.state.isBusy = true;

		// As a new barcode is scanned, the system must first check to see if it matches any of the rows already retrieved by the GET /packages endpoint.
		const response: EndpointResponse<PackagesModel> = await PackagesProvider.getPackagesAsync(barcode, true);

		if (response.wasSuccessful && response.data) {
			if (response.data.paging_info.fetched > 0) {
				// Where rows are returned by the GET /packages endpoint, the system will validate that the carrier_service_id returned is amongst those determined in the Determine Compatible Carrier Services section.
				const carrierServiceId: number = response.data.data[0].carrier_service.id; 
				const hasCompatibleCarrierService: boolean = this.state.compatibleCarrierServices.filter((carrierService: CarrierServiceModel) => {
					return carrierService.id === carrierServiceId;
				}).length > 0;

				// Where this is not the case, the user will be given a warning "Incompatible Service" and will be allowed to rescan a new package / cage.
				if (!hasCompatibleCarrierService) {
					this.setStatusScreenContent(
						"incompatible-service",
						["far", "ban"],
						"red"
					);
				}
				else {
					// Where matching rows are found but where all have already been added to the grid, the user will be given a warning "Package already scanned".
					const hasEveryPackage: boolean = response.data.data.every((packageModel: PackageModel) => {
						return this.state.packages?.data.some((exisitingPackage: PackageModel) => {
							packageModel.barcode = barcode;
							return packageModel.id === exisitingPackage.id && packageModel.barcode === exisitingPackage.barcode;
						}); 
					});
													
					if (hasEveryPackage) {
						this.setStatusScreenContent(
							"package-already-scanned",
							["far", "ban"],
							"red"
						);
					}
					else {
						response.data.data.forEach((packageModel: PackageModel) => {
							packageModel.barcode = barcode;
	
							if (this.state.packages) {
								const hasExisitingPackage: boolean = this.state.packages.data.filter((exisitingPackage: PackageModel) => {
									return packageModel.id === exisitingPackage.id && packageModel.barcode === exisitingPackage.barcode;
								}).length > 0;
		
								// Where an existing row is found which has not yet been added to the grid, this row will be added to the grid.
								if (!hasExisitingPackage) {
									this.state.packages.data.push(packageModel);
								}
							}
						});
					}
				}
			}
			// Where no rows have been returned by the GET /packages endpoint, it's possible that the user has scanned a cage. In order to determine if this is the case, the system will call the WMS BFF API GET /stores/{store_Id}/store_cages endpoint.
			else {
				this.checkIfCageHasBeenScanned(barcode);
			}
		}

		this.state.isBusy = false;
	}

	/**
	 * Checks if the user has scanned a cage.
	 */
	private async checkIfCageHasBeenScanned(barcode: string): Promise<void> {
		this.state.isBusy = true;

		if (this.state.packages) {
			const storeId: number = this.state.packages.data[0].store_id;
			const carrierServiceId: number = this.state.packages.data[0].carrier_service.id;

			const response: EndpointResponse<Array<StoreCageModel>> = await StoresProvider.getStoreCagesAsync(storeId, barcode, carrierServiceId);

			if (response.wasSuccessful && response.data) {
				if (response.data.length > 0) {
					this.state.cage = response.data[0];
					this.updatePackagesWithCarrierCage();
				}
				// Where an empty data model is returned (i.e. where nothing has been found by the Barcode scanned), the user will be presented with a warning "Invalid Package or Cage".
				else {
					this.setStatusScreenContent(
						"invalid-package-or-cage",
						["far", "ban"],
						"red"
					);
				}
			}
		}

		this.state.isBusy = false;
	}

	/**
	 * Update the packages in the list with the scanned cage.
	 */
	private updatePackagesWithCarrierCage(): void {
		if (this.state.packages && this.state.cage) {
			// The application will display a Confirmation to the user that the "Package/s have been successfully assigned to Cage".
			this.setStatusScreenContent(
				"successfully-assigned-to-cage",
				["far", "check-circle"],
				"green"
			);

			const packagesToUpdate: Array<PackageModel> = this.state.packages.data;
			const cage: StoreCageModel = this.state.cage;

			this.clear();

			packagesToUpdate.forEach((packageModel: PackageModel) => {
				const body: object = {
					store_cage_id: cage.id
				};

				PackagesProvider.updatePackageAsync(packageModel.id, JSON.stringify(body));
			});
		}
	}

	/**
	 * True if there are no packages, if so, show the scan prompt.
	 */
	public isScanPackagePromptVisible(): boolean {
		return this.state.packages === undefined;
	}

	/**
	 * True if the scan to cage view is busy.
	 */
	public isLoaderVisible(): boolean {
		return this.state.isBusy;
	}

	/**
	 * Returns a title for the packages screen.
	 */
	public getPackagesTitle(): string {
		return this.getText("packages", [this.state.packages?.data.length.toString() as string]);
	}

	/**
	 * Returns a placeholder for the scan input.
	 */
	public getInputPlaceholder(): string {
		return this.state.packages ? this.getText("scan-package-cage") : this.getText("scan-package");
	}

	/**
	 * Returns a reversed packages list.
	 */
	public getReversedPackagesList(): Array<PackageModel> {
		if (this.state.packages) {
			return this.state.packages.data.reverse();
		}
		else {
			return [];
		}
	}

	/**
	 * Returns a carrier for the packages screen.
	 */
	public getCageCarrier(): object | undefined {
		if (this.state.packages && this.state.packages.data.length > 0) {
			return {
				thumbnailImage: `/images/carriers/${this.state.packages.data[0].carrier.code}.svg`,
				leftTitle: this.state.packages.data[0].carrier.description,
				content: [
					{
						leftText: this.state.packages.data[0].carrier_service.description
					}
				]
			};
		}
		else {
			return undefined;
		}
	}

	/**
	 * Returns an icon to display in the header.
	 */
	public getHeaderIcon(): Array<string> | undefined {
		return this.state.packages ? ["far", "rotate-left"] : ["fas", "bars"];
	}

	/**
	 * Called when the user clicks the header icon.
	 */
	public onHeaderIconClicked(): void {
		if (this.state.packages) {
			this.showRestartDialog();
		}
		else {
			NavigationUtilities.isNavigationOpen.value = true;
		}
	}

	/**
	 * Displays the dialog to ask the user whether or not they want to restart the scanning process.
	 */
	private showRestartDialog(): void {
		if (this.state.packages) {
			this.state.dialogContent = {
				title: this.getText("start-again"),
				description: this.getText("are-you-sure-start-again"),
				buttons: [
					{
						text: this.getText("restart"),
						color: "blackcurrant",
						action: (): void => {
							this.state.dialogContent = undefined;
							this.clear();
						}
					},
					{
						text: this.getText("cancel"),
						color: "gray",
						action: (): void => {
							this.state.dialogContent = undefined;
						}
					}
				]
			};
		}
	}

	/**
	 * Returns delivery address content for the package list item.
	 */
	private getPackageDeliveryAddressContentItem(packageModel: PackageModel): FcListItemContentItem {
		let leftText: string = packageModel.delivery_address.contact_name;

		if (packageModel.delivery_address.organisation_name) {
			leftText = packageModel.delivery_address.organisation_name;
		}

		return {
			leftText,
			rightText: packageModel.delivery_address.postcode
		};
	}

	/**
	 * Returns content for the package list item.
	 */
	public getPackageListItemContent(packageModel: PackageModel): Array<FcListItemContentItem> {
		return [
			{
				leftText: packageModel.barcode,
			},
			this.getPackageDeliveryAddressContentItem(packageModel)
		];
	}
}

// Export the view model.
export let view: ScanToCageModel = new ScanToCageModel();

export function resetScanToCage(): void {
	view = new ScanToCageModel();
}