import { EndpointResponse } from "@/data/endpoint/types";
import * as Endpoint from "@/data/endpoint/endpoint";
import { CarrierServiceModel } from "../models/carrier-service-model";
import { StoreCageModel } from "../models/store-cage-model";
import { StoreCarrierCageModel } from "../models/store-carrier-cage-model";
import { StoreModel } from "../models/store-model";

// ----------------------------------------------------
// Provides data access functions for stores.
// ----------------------------------------------------

/**
 * Get a list of compatiable carrier services.
 */
export async function getCompatibleCarrierServicesAsync(carrier_service_id: number, store_id: number): Promise<EndpointResponse<Array<CarrierServiceModel>>> {
	return Endpoint.getAsync(`store_cages/compatible_carrier_services?carrier_service_id=${carrier_service_id}&store_id=${store_id}`);
}

/**
 * Get a list of store cages.
 */
export async function getStoreCagesAsync(id: number, barcode: string, carrier_service_id: number): Promise<EndpointResponse<Array<StoreCageModel>>> {
	return Endpoint.getAsync(`stores/${id}/store_cages?code=${encodeURIComponent(barcode)}&carrier_service_id=${carrier_service_id}`);
}

/**
 * Get a list of stores.
 */
export async function getStoresAsync(): Promise<EndpointResponse<Array<StoreModel>>> {
	return Endpoint.getAsync(`stores`);
}

/**
 * Get a list of store carrier cages.
 */
export async function getStoreCarrierCagesAsync(): Promise<EndpointResponse<Array<StoreCarrierCageModel>>> {
	return Endpoint.getAsync(`store_carrier_cages`);
}

/**
 * Sets a store cage to collected.
 */
export async function setStoreCageToCollectedAsync(id: number): Promise<EndpointResponse<StoreCageModel>> {
	return Endpoint.postAsync(`store_cages/${id}/collected`);
}